import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import "../styles/index.scss"
import { Navbar } from "./Navbar"

interface Props {
  children: React.ReactNode;
}

export const Layout = ({ children }: Props) => {
  const data = useStaticQuery(graphql`
      query SiteTitleQuery {
          site {
              siteMetadata {
                  title
              }
          }
      }
  `)

  const siteTitle = data.site.siteMetadata.title

  return (
    <>
      <Navbar siteTitle={ siteTitle } />
      <main>{ children }</main>
      <footer className="has-text-centered">
        © { new Date().getFullYear() } { siteTitle }
      </footer>
    </>
  )
}
