/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import * as React from "react"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

interface Props {
  description?: string;
  lang?: string;
  meta?: { name: string, content: string }[];
  title: string;
  ogImage?: string;
}

function SEO({ description = "", lang = "en", meta = [], title, ogImage }: Props) {
  const { site } = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                    siteUrl
                    image
                }
            }
        }
    `,
  )

  const metadata = site.siteMetadata
  const metaDescription = description || metadata.description
  const image = ogImage || metadata.image
  const siteUrl = metadata.siteUrl

  return (
    <Helmet
      htmlAttributes={ {
        lang,
      } }
      title={ title }
      titleTemplate={ `%s | ${ metadata.title }` }
      meta={ [
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:image",
          content: `${ siteUrl }${ image }`,
        },
        {
          property: "og:image:width",
          content: "692",
        },
        {
          property: "og:image:height",
          content: "692",
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: metadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta) }
    >
      { /* -- Global site tag (gtag.js) - Google Analytics --> */ }
      <script async src="https://www.googletagmanager.com/gtag/js?id=UA-145829806-1" />
      <script>
        {
          `window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', 'UA-145829806-1');`
        }
      </script>

    </Helmet>
  )
}

export default SEO
