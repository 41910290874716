import * as React from "react"

import { GatsbyAstronautImage } from "./GatsbyAstronautImage"
import { GatsbyIconImage } from "./GatsbyIconImage"
import { ElectrumProFrontImage } from "./electrum/pro/ElectrumProFrontImage"
import { ElectrumProTurnedImage } from "./electrum/pro/ElectrumProTurnedImage"
import { EngageApprenticeWhiteFrontImage } from "./engage/apprentice/EngageApprenticeWhiteFrontImage"
import { EngageEliteProRedFrontImage } from "./engage/elite-pro/EngageEliteProRedFrontImage"
import { EngageEliteProWhiteFrontImage } from "./engage/elite-pro/EngageEliteProWhiteFrontImage"
import { EngageEliteProBladeRedFrontImage } from "./engage/elite-pro-blade/EngageEliteProBladeRedFrontImage"
import { EngageEliteProBladeWhiteFrontImage } from "./engage/elite-pro-blade/EngageEliteProBladeWhiteFrontImage"
import { EngageEliteProWidebodyRedFrontImage } from "./engage/elite-pro-widebody/EngageEliteProWidebodyRedFrontImage"
import { EngageEliteProWidebodyWhiteFrontImage } from "./engage/elite-pro-widebody/EngageEliteProWidebodyWhiteFrontImage"
import { EngageEliteProMaverickBlackFrontImage } from "./engage/elite-pro-maverick/EngageEliteProMaverickBlackFrontImage"
import { EngageEliteProMaverickBlueFrontImage } from "./engage/elite-pro-maverick/EngageEliteProMaverickBlueFrontImage"
import { EngageEliteProMaverickOrangeFrontImage } from "./engage/elite-pro-maverick/EngageEliteProMaverickOrangeFrontImage"
import { EngageEliteProMaverickPurpleFrontImage } from "./engage/elite-pro-maverick/EngageEliteProMaverickPurpleFrontImage"
import { EngageEliteProMaverickRedFrontImage } from "./engage/elite-pro-maverick/EngageEliteProMaverickRedFrontImage"
import { EngageEncoreBlueFrontImage } from "./engage/encore/EngageEncoreBlueFrontImage"
import { EngageEncoreGigiFrontImage } from "./engage/encore/EngageEncoreGigiFrontImage"
import { EngageEncorePinkFrontImage } from "./engage/encore/EngageEncorePinkFrontImage"
import { EngageEncorePurpleFrontImage } from "./engage/encore/EngageEncorePurpleFrontImage"
import { EngageEncorePurpleSwirlFrontImage } from "./engage/encore/EngageEncorePurpleSwirlFrontImage"
import { EngageEncoreRedFrontImage } from "./engage/encore/EngageEncoreRedFrontImage"
import { EngageEncoreProBlackFrontImage } from "./engage/encore-pro/EngageEncoreProBlackFrontImage"
import { EngageEncoreProGreenFrontImage } from "./engage/encore-pro/EngageEncoreProGreenFrontImage"
import { EngageEncoreProPurpleFrontImage } from "./engage/encore-pro/EngageEncoreProPurpleFrontImage"
import { EngageEncoreProRedFrontImage } from "./engage/encore-pro/EngageEncoreProRedFrontImage"
import { EngageEncoreXlBlueFrontImage } from "./engage/encore-xl/EngageEncoreXlBlueFrontImage"
import { EngageEncoreXlPurpleFrontImage } from "./engage/encore-xl/EngageEncoreXlPurpleFrontImage"
import { EngageEncoreXlRedFrontImage } from "./engage/encore-xl/EngageEncoreXlRedFrontImage"
import { EngageOmegaStatusBlueFrontImage } from "./engage/omega-status/EngageOmegaStatusBlueFrontImage"
import { EngageOmegaStatusOrangeFrontImage } from "./engage/omega-status/EngageOmegaStatusOrangeFrontImage"
import { EngagePoachAdvantageBlueFrontImage } from "./engage/poach-advantage/EngagePoachAdvantageBlueFrontImage"
import { EngagePoachAdvantageGreenFrontImage } from "./engage/poach-advantage/EngagePoachAdvantageGreenFrontImage"
import { EngagePoachAdvantagePurpleFrontImage } from "./engage/poach-advantage/EngagePoachAdvantagePurpleFrontImage"
import { EngagePoachAdvantageRedFrontImage } from "./engage/poach-advantage/EngagePoachAdvantageRedFrontImage"
import { EngagePoachAdvantageWhiteFrontImage } from "./engage/poach-advantage/EngagePoachAdvantageWhiteFrontImage"
import { EngagePoachExtremeBlueFrontImage } from "./engage/poach-extreme/EngagePoachExtremeBlueFrontImage"
import { EngagePoachExtremeGreenFrontImage } from "./engage/poach-extreme/EngagePoachExtremeGreenFrontImage"
import { EngagePoachExtremePurpleFrontImage } from "./engage/poach-extreme/EngagePoachExtremePurpleFrontImage"
import { EngagePoachExtremeRedFrontImage } from "./engage/poach-extreme/EngagePoachExtremeRedFrontImage"
import { EngagePoachIconAquaFrontImage } from "./engage/poach-icon/EngagePoachIconAquaFrontImage"
import { EngagePoachIconBlackFrontImage } from "./engage/poach-icon/EngagePoachIconBlackFrontImage"
import { EngagePoachIconRedFrontImage } from "./engage/poach-icon/EngagePoachIconRedFrontImage"
import { EngagePoachIconWhiteFrontImage } from "./engage/poach-icon/EngagePoachIconWhiteFrontImage"
import { EngageRavenBlueFrontImage } from "./engage/raven/EngageRavenBlueFrontImage"
import { EngageRavenGreenFrontImage } from "./engage/raven/EngageRavenGreenFrontImage"
import { EngageRavenPurpleFrontImage } from "./engage/raven/EngageRavenPurpleFrontImage"
import { EngageRavenRedFrontImage } from "./engage/raven/EngageRavenRedFrontImage"
import { EngageTridentAquaFrontImage } from "./engage/trident/EngageTridentAquaFrontImage"
import { EngageTridentBlackFrontImage } from "./engage/trident/EngageTridentBlackFrontImage"
import { EngageTridentBlueFrontImage } from "./engage/trident/EngageTridentBlueFrontImage"
import { EngageTridentGoldFrontImage } from "./engage/trident/EngageTridentGoldFrontImage"
import { EngageTridentPurpleFrontImage } from "./engage/trident/EngageTridentPurpleFrontImage"
import { EngageTridentRedFrontImage } from "./engage/trident/EngageTridentRedFrontImage"
import { GammaRzrBlueImage } from "./gamma/RZR/GammaRzrBlueImage"
import { GammaRzrGreenImage } from "./gamma/RZR/GammaRzrGreenImage"
import { GammaRzrGripImage } from "./gamma/RZR/GammaRzrGripImage"
import { GammaRzrPinkImage } from "./gamma/RZR/GammaRzrPinkImage"
import { GammaCompassFrontImage } from "./gamma/compass/GammaCompassFrontImage"
import { GammaCompassGripImage } from "./gamma/compass/GammaCompassGripImage"
import { GammaLegendBlackImage } from "./gamma/legend/GammaLegendBlackImage"
import { GammaLegendGripImage } from "./gamma/legend/GammaLegendGripImage"
import { GammaLegendWhiteImage } from "./gamma/legend/GammaLegendWhiteImage"
import { GammaMirageBlueImage } from "./gamma/mirage/GammaMirageBlueImage"
import { GammaMirageGripImage } from "./gamma/mirage/GammaMirageGripImage"
import { GammaMirageOrangeImage } from "./gamma/mirage/GammaMirageOrangeImage"
import { GammaMirageRedBlueImage } from "./gamma/mirage/GammaMirageRedBlueImage"
import { GammaNeedleFrontImage } from "./gamma/needle/GammaNeedleFrontImage"
import { GammaNeedleGripImage } from "./gamma/needle/GammaNeedleGripImage"
import { GammaNeutronFrontImage } from "./gamma/neutron/GammaNeutronFrontImage"
import { GammaNeutronGripImage } from "./gamma/neutron/GammaNeutronGripImage"
import { GammaPinFrontImage } from "./gamma/pin/GammaPinFrontImage"
import { GammaPinGripImage } from "./gamma/pin/GammaPinGripImage"
import { GammaShardBlackImage } from "./gamma/shard/GammaShardBlackImage"
import { GammaShardBlueImage } from "./gamma/shard/GammaShardBlueImage"
import { GammaShardGripImage } from "./gamma/shard/GammaShardGripImage"
import { GammaVoltageFrontImage } from "./gamma/voltage/GammaVoltageFrontImage"
import { GammaVoltageGripImage } from "./gamma/voltage/GammaVoltageGripImage"
import { GearboxGx5ControlOrangeFrontImage } from "./gearbox/gx5-control/GearboxGx5ControlOrangeFrontImage"
import { GearboxGx5ControlRedFrontImage } from "./gearbox/gx5-control/GearboxGx5ControlRedFrontImage"
import { GearboxGx5PowerBlueFrontImage } from "./gearbox/gx5-power/GearboxGx5PowerBlueFrontImage"
import { GearboxGx5PowerGreenFrontImage } from "./gearbox/gx5-power/GearboxGx5PowerGreenFrontImage"
import { GearboxGx6ControlGreenFrontImage } from "./gearbox/gx6-control/GearboxGx6ControlGreenFrontImage"
import { GearboxGx6ControlRedFrontImage } from "./gearbox/gx6-control/GearboxGx6ControlRedFrontImage"
import { GearboxGx6PowerBlueFrontImage } from "./gearbox/gx6-power/GearboxGx6PowerBlueFrontImage"
import { GearboxGx6PowerNeonFrontImage } from "./gearbox/gx6-power/GearboxGx6PowerNeonFrontImage"
import { HeadChangesInLatitudesFrontImage } from "./head/changes-in-latitudes/HeadChangesInLatitudesFrontImage"
import { HeadExtremeEliteFrontImage } from "./head/extreme-elite/HeadExtremeEliteFrontImage"
import { HeadExtremeLiteFrontImage } from "./head/extreme-lite/HeadExtremeLiteFrontImage"
import { HeadExtremeProFrontImage } from "./head/extreme-pro/HeadExtremeProFrontImage"
import { HeadExtremeTourFrontImage } from "./head/extreme-tour/HeadExtremeTourFrontImage"
import { HeadFinsFrontImage } from "./head/fins/HeadFinsFrontImage"
import { HeadIts5OclockSomewhereImage } from "./head/its-5-oclock-somewhere/HeadIts5OclockSomewhereImage"
import { HeadRadicalEliteFrontImage } from "./head/radical-elite/HeadRadicalEliteFrontImage"
import { HeadRadicalProFrontImage } from "./head/radical-pro/HeadRadicalProFrontImage"
import { HeadRadicalTourFrontImage } from "./head/radical-tour/HeadRadicalTourFrontImage"
import { HeadRadicalTourLFrontImage } from "./head/radical-tour-l/HeadRadicalTourLFrontImage"
import { HeadRadicalXlBlueFrontImage } from "./head/radical-xl/HeadRadicalXlBlueFrontImage"
import { HeadRadicalXlRedFrontImage } from "./head/radical-xl/HeadRadicalXlRedFrontImage"
import { HeadWashedInTheOceanFrontImage } from "./head/washed-in-the-ocean/HeadWashedInTheOceanFrontImage"
import { HudefHammerBlackFrontImage } from "./hudef/hammer/HudefHammerBlackFrontImage"
import { HudefHammerGoldFrontImage } from "./hudef/hammer/HudefHammerGoldFrontImage"
import { HudefHammerOrangeFrontImage } from "./hudef/hammer/HudefHammerOrangeFrontImage"
import { HudefHb20BlueFrontImage } from "./hudef/hb2/HudefHb20BlueFrontImage"
import { HudefHb20OrangeFrontImage } from "./hudef/hb2/HudefHb20OrangeFrontImage"
import { HudefHb20PurpleFrontImage } from "./hudef/hb2/HudefHb20PurpleFrontImage"
import { HudefHb20RedFrontImage } from "./hudef/hb2/HudefHb20RedFrontImage"
import { OnixCompositeEvokeTeardropBlackFrontImage } from "./onix/composite-evoke-teardrop/OnixCompositeEvokeTeardropBlackFrontImage"
import { OnixCompositeEvokeTeardropBlueFrontImage } from "./onix/composite-evoke-teardrop/OnixCompositeEvokeTeardropBlueFrontImage"
import { OnixCompositeEvokeTeardropGreenFrontImage } from "./onix/composite-evoke-teardrop/OnixCompositeEvokeTeardropGreenFrontImage"
import { OnixCompositeEvokeTeardropPurpleFrontImage } from "./onix/composite-evoke-teardrop/OnixCompositeEvokeTeardropPurpleFrontImage"
import { OnixCompositeEvokeTeardropRedFrontImage } from "./onix/composite-evoke-teardrop/OnixCompositeEvokeTeardropRedFrontImage"
import { OnixCompositeZ5BlackFrontImage } from "./onix/composite-z5/OnixCompositeZ5BlackFrontImage"
import { OnixCompositeZ5BlueFrontImage } from "./onix/composite-z5/OnixCompositeZ5BlueFrontImage"
import { OnixCompositeZ5GreenFrontImage } from "./onix/composite-z5/OnixCompositeZ5GreenFrontImage"
import { OnixCompositeZ5OrangeFrontImage } from "./onix/composite-z5/OnixCompositeZ5OrangeFrontImage"
import { OnixCompositeZ5PinkFrontImage } from "./onix/composite-z5/OnixCompositeZ5PinkFrontImage"
import { OnixCompositeZ5PurpleFrontImage } from "./onix/composite-z5/OnixCompositeZ5PurpleFrontImage"
import { OnixCompositeZ5RedFrontImage } from "./onix/composite-z5/OnixCompositeZ5RedFrontImage"
import { OnixCompositeZ5YellowFrontImage } from "./onix/composite-z5/OnixCompositeZ5YellowFrontImage"
import { OnixEvokePremierFrontImage } from "./onix/evoke-premier/OnixEvokePremierFrontImage"
import { OnixEvokeProBlueFrontImage } from "./onix/evoke-pro/OnixEvokeProBlueFrontImage"
import { OnixEvokeProGreenFrontImage } from "./onix/evoke-pro/OnixEvokeProGreenFrontImage"
import { OnixEvokeProOrangeFrontImage } from "./onix/evoke-pro/OnixEvokeProOrangeFrontImage"
import { OnixEvokeProPurpleFrontImage } from "./onix/evoke-pro/OnixEvokeProPurpleFrontImage"
import { OnixGraphiteEvokeTeardropBlueFrontImage } from "./onix/graphite-evoke-teardrop/OnixGraphiteEvokeTeardropBlueFrontImage"
import { OnixGraphiteEvokeTeardropGreenFrontImage } from "./onix/graphite-evoke-teardrop/OnixGraphiteEvokeTeardropGreenFrontImage"
import { OnixGraphiteEvokeTeardropPurpleFrontImage } from "./onix/graphite-evoke-teardrop/OnixGraphiteEvokeTeardropPurpleFrontImage"
import { OnixGraphiteEvokeTeardropRedFrontImage } from "./onix/graphite-evoke-teardrop/OnixGraphiteEvokeTeardropRedFrontImage"
import { OnixGraphiteEvokeTeardropWhiteFrontImage } from "./onix/graphite-evoke-teardrop/OnixGraphiteEvokeTeardropWhiteFrontImage"
import { OnixGraphiteZ5BlueFrontImage } from "./onix/graphite-z5/OnixGraphiteZ5BlueFrontImage"
import { OnixGraphiteZ5BlueNewFrontImage } from "./onix/graphite-z5/OnixGraphiteZ5BlueNewFrontImage"
import { OnixGraphiteZ5GreenFrontImage } from "./onix/graphite-z5/OnixGraphiteZ5GreenFrontImage"
import { OnixGraphiteZ5OrangeFrontImage } from "./onix/graphite-z5/OnixGraphiteZ5OrangeFrontImage"
import { OnixGraphiteZ5PinkFrontImage } from "./onix/graphite-z5/OnixGraphiteZ5PinkFrontImage"
import { OnixGraphiteZ5PinkNewFrontImage } from "./onix/graphite-z5/OnixGraphiteZ5PinkNewFrontImage"
import { OnixGraphiteZ5PurpleFrontImage } from "./onix/graphite-z5/OnixGraphiteZ5PurpleFrontImage"
import { OnixGraphiteZ5RedFrontImage } from "./onix/graphite-z5/OnixGraphiteZ5RedFrontImage"
import { OnixGraphiteZ5WhiteFrontImage } from "./onix/graphite-z5/OnixGraphiteZ5WhiteFrontImage"
import { OnixGraphiteZ5YellowFrontImage } from "./onix/graphite-z5/OnixGraphiteZ5YellowFrontImage"
import { OnixOutbreakFrontImage } from "./onix/outbreak/OnixOutbreakFrontImage"
import { OnixReactBlackFrontImage } from "./onix/react/OnixReactBlackFrontImage"
import { OnixReactGreenFrontImage } from "./onix/react/OnixReactGreenFrontImage"
import { OnixStryker4CompositeBlueFrontImage } from "./onix/stryker-4-composite/OnixStryker4CompositeBlueFrontImage"
import { OnixStryker4CompositeGreenFrontImage } from "./onix/stryker-4-composite/OnixStryker4CompositeGreenFrontImage"
import { OnixStryker4CompositeRedFrontImage } from "./onix/stryker-4-composite/OnixStryker4CompositeRedFrontImage"
import { OnixStryker4GraphitePurpleFrontImage } from "./onix/stryker-4-graphite/OnixStryker4GraphitePurpleFrontImage"
import { OnixStryker4GraphiteWhiteFrontImage } from "./onix/stryker-4-graphite/OnixStryker4GraphiteWhiteFrontImage"
import { OnixSummitC1OrangeFrontImage } from "./onix/summit-c1/OnixSummitC1OrangeFrontImage"
import { OnixVertexRedFrontImage } from "./onix/vertex/OnixVertexRedFrontImage"
import { OnixVoyagerProWhiteFrontImage } from "./onix/voyager-pro/OnixVoyagerProWhiteFrontImage"
import { PaddletekBantamExLBlueFrontImage } from "./paddletek/bantam-ex-l/PaddletekBantamExLBlueFrontImage"
import { PaddletekBantamExLPinkFrontImage } from "./paddletek/bantam-ex-l/PaddletekBantamExLPinkFrontImage"
import { PaddletekBantamExLPurpleFrontImage } from "./paddletek/bantam-ex-l/PaddletekBantamExLPurpleFrontImage"
import { PaddletekBantamExLRedFrontImage } from "./paddletek/bantam-ex-l/PaddletekBantamExLRedFrontImage"
import { PaddletekBantamExLProAquaFrontImage } from "./paddletek/bantam-ex-l-pro/PaddletekBantamExLProAquaFrontImage"
import { PaddletekBantamExLProBlueFrontImage } from "./paddletek/bantam-ex-l-pro/PaddletekBantamExLProBlueFrontImage"
import { PaddletekBantamExLProPinkFrontImage } from "./paddletek/bantam-ex-l-pro/PaddletekBantamExLProPinkFrontImage"
import { PaddletekBantamExLProRedFrontImage } from "./paddletek/bantam-ex-l-pro/PaddletekBantamExLProRedFrontImage"
import { PaddletekBantamTs5BlueFrontImage } from "./paddletek/bantam-ts-5/PaddletekBantamTs5BlueFrontImage"
import { PaddletekBantamTs5GrayFrontImage } from "./paddletek/bantam-ts-5/PaddletekBantamTs5GrayFrontImage"
import { PaddletekBantamTs5PinkFrontImage } from "./paddletek/bantam-ts-5/PaddletekBantamTs5PinkFrontImage"
import { PaddletekBantamTs5RedFrontImage } from "./paddletek/bantam-ts-5/PaddletekBantamTs5RedFrontImage"
import { PaddletekBantamTs5YellowFrontImage } from "./paddletek/bantam-ts-5/PaddletekBantamTs5YellowFrontImage"
import { PaddletekBantamTs5ProAquaFrontImage } from "./paddletek/bantam-ts-5-pro/PaddletekBantamTs5ProAquaFrontImage"
import { PaddletekBantamTs5ProBlueFrontImage } from "./paddletek/bantam-ts-5-pro/PaddletekBantamTs5ProBlueFrontImage"
import { PaddletekBantamTs5ProPurpleFrontImage } from "./paddletek/bantam-ts-5-pro/PaddletekBantamTs5ProPurpleFrontImage"
import { PaddletekBantamTs5ProRedFrontImage } from "./paddletek/bantam-ts-5-pro/PaddletekBantamTs5ProRedFrontImage"
import { PaddletekElementBlueFrontImage } from "./paddletek/element/PaddletekElementBlueFrontImage"
import { PaddletekElementPinkFrontImage } from "./paddletek/element/PaddletekElementPinkFrontImage"
import { PaddletekElementRedFrontImage } from "./paddletek/element/PaddletekElementRedFrontImage"
import { PaddletekElementYellowFrontImage } from "./paddletek/element/PaddletekElementYellowFrontImage"
import { PaddletekPhoenixProBlackFrontImage } from "./paddletek/phoenix-pro/PaddletekPhoenixProBlackFrontImage"
import { PaddletekPhoenixProBlueFrontImage } from "./paddletek/phoenix-pro/PaddletekPhoenixProBlueFrontImage"
import { PaddletekPhoenixProGreenFrontImage } from "./paddletek/phoenix-pro/PaddletekPhoenixProGreenFrontImage"
import { PaddletekPhoenixProOrangeFrontImage } from "./paddletek/phoenix-pro/PaddletekPhoenixProOrangeFrontImage"
import { PaddletekPhoenixProPinkFrontImage } from "./paddletek/phoenix-pro/PaddletekPhoenixProPinkFrontImage"
import { PaddletekPhoenixProPurpleFrontImage } from "./paddletek/phoenix-pro/PaddletekPhoenixProPurpleFrontImage"
import { PaddletekPhoenixProRedFrontImage } from "./paddletek/phoenix-pro/PaddletekPhoenixProRedFrontImage"
import { PaddletekPhoenixProTurquoiseFrontImage } from "./paddletek/phoenix-pro/PaddletekPhoenixProTurquoiseFrontImage"
import { PaddletekPhoenixProYellowFrontImage } from "./paddletek/phoenix-pro/PaddletekPhoenixProYellowFrontImage"
import { PaddletekSabreProBlueFrontImage } from "./paddletek/sabre-pro/PaddletekSabreProBlueFrontImage"
import { PaddletekSabreProRedFrontImage } from "./paddletek/sabre-pro/PaddletekSabreProRedFrontImage"
import { PaddletekTangentProBlueFrontImage } from "./paddletek/tangent-pro/PaddletekTangentProBlueFrontImage"
import { PaddletekTangentProRedFrontImage } from "./paddletek/tangent-pro/PaddletekTangentProRedFrontImage"
import { PaddletekTempestProBlueFrontImage } from "./paddletek/tempest-pro/PaddletekTempestProBlueFrontImage"
import { PaddletekTempestProRedFrontImage } from "./paddletek/tempest-pro/PaddletekTempestProRedFrontImage"
import { PaddletekTempestWaveBlueFrontImage } from "./paddletek/tempest-wave/PaddletekTempestWaveBlueFrontImage"
import { PaddletekTempestWavePinkFrontImage } from "./paddletek/tempest-wave/PaddletekTempestWavePinkFrontImage"
import { PaddletekTempestWaveRedFrontImage } from "./paddletek/tempest-wave/PaddletekTempestWaveRedFrontImage"
import { ProliteChromeNRGBlueFrontImage } from "./prolite/chrome-n-r-g/ProliteChromeNRGBlueFrontImage"
import { ProliteChromeNRGRedFrontImage } from "./prolite/chrome-n-r-g/ProliteChromeNRGRedFrontImage"
import { ProliteChromeNRGWhiteFrontImage } from "./prolite/chrome-n-r-g/ProliteChromeNRGWhiteFrontImage"
import { ProliteCrushPowerspinSkateFrontImage } from "./prolite/crush-powerspin/ProliteCrushPowerspinSkateFrontImage"
import { ProliteCrushPowerspinSnowFrontImage } from "./prolite/crush-powerspin/ProliteCrushPowerspinSnowFrontImage"
import { ProliteCrushPowerspinSurfFrontImage } from "./prolite/crush-powerspin/ProliteCrushPowerspinSurfFrontImage"
import { ProliteCypherProFrontImage } from "./prolite/cypher-pro/ProliteCypherProFrontImage"
import { ProliteLargeTitanFrontImage } from "./prolite/large-titan/ProliteLargeTitanFrontImage"
import { ProliteMagnumGsBlueFrontImage } from "./prolite/magnum-gs/ProliteMagnumGsBlueFrontImage"
import { ProliteMagnumGsRedFrontImage } from "./prolite/magnum-gs/ProliteMagnumGsRedFrontImage"
import { ProliteRebelPowerspinBlueFrontImage } from "./prolite/rebel-powerspin/ProliteRebelPowerspinBlueFrontImage"
import { ProliteRebelPowerspinGoldFrontImage } from "./prolite/rebel-powerspin/ProliteRebelPowerspinGoldFrontImage"
import { ProliteRebelPowerspinPurpleFrontImage } from "./prolite/rebel-powerspin/ProliteRebelPowerspinPurpleFrontImage"
import { ProliteRebelPowerspinRedFrontImage } from "./prolite/rebel-powerspin/ProliteRebelPowerspinRedFrontImage"
import { ProliteSupernovaProFrontImage } from "./prolite/supernova-pro/ProliteSupernovaProFrontImage"
import { ProliteSurfaceNrgBlueFrontImage } from "./prolite/surface-nrg/ProliteSurfaceNrgBlueFrontImage"
import { ProliteSurfaceNrgGreenFrontImage } from "./prolite/surface-nrg/ProliteSurfaceNrgGreenFrontImage"
import { ProliteSurfaceNrgOrangeFrontImage } from "./prolite/surface-nrg/ProliteSurfaceNrgOrangeFrontImage"
import { ProliteSurfaceNrgPurpleFrontImage } from "./prolite/surface-nrg/ProliteSurfaceNrgPurpleFrontImage"
import { ProliteTitanProFrontImage } from "./prolite/titan-pro/ProliteTitanProFrontImage"
import { SelkirkAmpedEpicFrontImage } from "./selkirk/amped-epic/SelkirkAmpedEpicFrontImage"
import { SelkirkAmpedInviktaFrontImage } from "./selkirk/amped-invikta/SelkirkAmpedInviktaFrontImage"
import { SelkirkAmpedMaximaFrontImage } from "./selkirk/amped-maxima/SelkirkAmpedMaximaFrontImage"
import { SelkirkAmpedOmniFrontImage } from "./selkirk/amped-omni/SelkirkAmpedOmniFrontImage"
import { SelkirkAmpedS2FrontImage } from "./selkirk/amped-s2/SelkirkAmpedS2FrontImage"
import { SelkirkPrimeEpicFrontImage } from "./selkirk/prime-epic/SelkirkPrimeEpicFrontImage"
import { SelkirkPrimeS2FrontImage } from "./selkirk/prime-s2/SelkirkPrimeS2FrontImage"


const getCapitalizeName = (filePath: string): string => {
  if (!filePath) {
    return ""
  }
  const fileName = filePath.substring(filePath.lastIndexOf("/") + 1, filePath.lastIndexOf("."))
  const nameParts = fileName.split("-")
  const name = nameParts.reduce((prev, curr) => {
    return prev + curr.charAt(0).toUpperCase() + curr.slice(1)
  }, "")

  return name + "Image"
}

interface Props {
  filePath: string;
  
  [x: string]: string;
}

export const Image = ({ filePath, ...extras }: Props): null | React.ReactElement => {
  const componentName = getCapitalizeName(filePath)
  if (!componentName) {
    return null
  }

  switch (componentName) {
    
    case "GatsbyAstronautImage":
      return <GatsbyAstronautImage { ...extras }/>
    case "GatsbyIconImage":
      return <GatsbyIconImage { ...extras }/>
    case "ElectrumProFrontImage":
      return <ElectrumProFrontImage { ...extras }/>
    case "ElectrumProTurnedImage":
      return <ElectrumProTurnedImage { ...extras }/>
    case "EngageApprenticeWhiteFrontImage":
      return <EngageApprenticeWhiteFrontImage { ...extras }/>
    case "EngageEliteProRedFrontImage":
      return <EngageEliteProRedFrontImage { ...extras }/>
    case "EngageEliteProWhiteFrontImage":
      return <EngageEliteProWhiteFrontImage { ...extras }/>
    case "EngageEliteProBladeRedFrontImage":
      return <EngageEliteProBladeRedFrontImage { ...extras }/>
    case "EngageEliteProBladeWhiteFrontImage":
      return <EngageEliteProBladeWhiteFrontImage { ...extras }/>
    case "EngageEliteProWidebodyRedFrontImage":
      return <EngageEliteProWidebodyRedFrontImage { ...extras }/>
    case "EngageEliteProWidebodyWhiteFrontImage":
      return <EngageEliteProWidebodyWhiteFrontImage { ...extras }/>
    case "EngageEliteProMaverickBlackFrontImage":
      return <EngageEliteProMaverickBlackFrontImage { ...extras }/>
    case "EngageEliteProMaverickBlueFrontImage":
      return <EngageEliteProMaverickBlueFrontImage { ...extras }/>
    case "EngageEliteProMaverickOrangeFrontImage":
      return <EngageEliteProMaverickOrangeFrontImage { ...extras }/>
    case "EngageEliteProMaverickPurpleFrontImage":
      return <EngageEliteProMaverickPurpleFrontImage { ...extras }/>
    case "EngageEliteProMaverickRedFrontImage":
      return <EngageEliteProMaverickRedFrontImage { ...extras }/>
    case "EngageEncoreBlueFrontImage":
      return <EngageEncoreBlueFrontImage { ...extras }/>
    case "EngageEncoreGigiFrontImage":
      return <EngageEncoreGigiFrontImage { ...extras }/>
    case "EngageEncorePinkFrontImage":
      return <EngageEncorePinkFrontImage { ...extras }/>
    case "EngageEncorePurpleFrontImage":
      return <EngageEncorePurpleFrontImage { ...extras }/>
    case "EngageEncorePurpleSwirlFrontImage":
      return <EngageEncorePurpleSwirlFrontImage { ...extras }/>
    case "EngageEncoreRedFrontImage":
      return <EngageEncoreRedFrontImage { ...extras }/>
    case "EngageEncoreProBlackFrontImage":
      return <EngageEncoreProBlackFrontImage { ...extras }/>
    case "EngageEncoreProGreenFrontImage":
      return <EngageEncoreProGreenFrontImage { ...extras }/>
    case "EngageEncoreProPurpleFrontImage":
      return <EngageEncoreProPurpleFrontImage { ...extras }/>
    case "EngageEncoreProRedFrontImage":
      return <EngageEncoreProRedFrontImage { ...extras }/>
    case "EngageEncoreXlBlueFrontImage":
      return <EngageEncoreXlBlueFrontImage { ...extras }/>
    case "EngageEncoreXlPurpleFrontImage":
      return <EngageEncoreXlPurpleFrontImage { ...extras }/>
    case "EngageEncoreXlRedFrontImage":
      return <EngageEncoreXlRedFrontImage { ...extras }/>
    case "EngageOmegaStatusBlueFrontImage":
      return <EngageOmegaStatusBlueFrontImage { ...extras }/>
    case "EngageOmegaStatusOrangeFrontImage":
      return <EngageOmegaStatusOrangeFrontImage { ...extras }/>
    case "EngagePoachAdvantageBlueFrontImage":
      return <EngagePoachAdvantageBlueFrontImage { ...extras }/>
    case "EngagePoachAdvantageGreenFrontImage":
      return <EngagePoachAdvantageGreenFrontImage { ...extras }/>
    case "EngagePoachAdvantagePurpleFrontImage":
      return <EngagePoachAdvantagePurpleFrontImage { ...extras }/>
    case "EngagePoachAdvantageRedFrontImage":
      return <EngagePoachAdvantageRedFrontImage { ...extras }/>
    case "EngagePoachAdvantageWhiteFrontImage":
      return <EngagePoachAdvantageWhiteFrontImage { ...extras }/>
    case "EngagePoachExtremeBlueFrontImage":
      return <EngagePoachExtremeBlueFrontImage { ...extras }/>
    case "EngagePoachExtremeGreenFrontImage":
      return <EngagePoachExtremeGreenFrontImage { ...extras }/>
    case "EngagePoachExtremePurpleFrontImage":
      return <EngagePoachExtremePurpleFrontImage { ...extras }/>
    case "EngagePoachExtremeRedFrontImage":
      return <EngagePoachExtremeRedFrontImage { ...extras }/>
    case "EngagePoachIconAquaFrontImage":
      return <EngagePoachIconAquaFrontImage { ...extras }/>
    case "EngagePoachIconBlackFrontImage":
      return <EngagePoachIconBlackFrontImage { ...extras }/>
    case "EngagePoachIconRedFrontImage":
      return <EngagePoachIconRedFrontImage { ...extras }/>
    case "EngagePoachIconWhiteFrontImage":
      return <EngagePoachIconWhiteFrontImage { ...extras }/>
    case "EngageRavenBlueFrontImage":
      return <EngageRavenBlueFrontImage { ...extras }/>
    case "EngageRavenGreenFrontImage":
      return <EngageRavenGreenFrontImage { ...extras }/>
    case "EngageRavenPurpleFrontImage":
      return <EngageRavenPurpleFrontImage { ...extras }/>
    case "EngageRavenRedFrontImage":
      return <EngageRavenRedFrontImage { ...extras }/>
    case "EngageTridentAquaFrontImage":
      return <EngageTridentAquaFrontImage { ...extras }/>
    case "EngageTridentBlackFrontImage":
      return <EngageTridentBlackFrontImage { ...extras }/>
    case "EngageTridentBlueFrontImage":
      return <EngageTridentBlueFrontImage { ...extras }/>
    case "EngageTridentGoldFrontImage":
      return <EngageTridentGoldFrontImage { ...extras }/>
    case "EngageTridentPurpleFrontImage":
      return <EngageTridentPurpleFrontImage { ...extras }/>
    case "EngageTridentRedFrontImage":
      return <EngageTridentRedFrontImage { ...extras }/>
    case "GammaRzrBlueImage":
      return <GammaRzrBlueImage { ...extras }/>
    case "GammaRzrGreenImage":
      return <GammaRzrGreenImage { ...extras }/>
    case "GammaRzrGripImage":
      return <GammaRzrGripImage { ...extras }/>
    case "GammaRzrPinkImage":
      return <GammaRzrPinkImage { ...extras }/>
    case "GammaCompassFrontImage":
      return <GammaCompassFrontImage { ...extras }/>
    case "GammaCompassGripImage":
      return <GammaCompassGripImage { ...extras }/>
    case "GammaLegendBlackImage":
      return <GammaLegendBlackImage { ...extras }/>
    case "GammaLegendGripImage":
      return <GammaLegendGripImage { ...extras }/>
    case "GammaLegendWhiteImage":
      return <GammaLegendWhiteImage { ...extras }/>
    case "GammaMirageBlueImage":
      return <GammaMirageBlueImage { ...extras }/>
    case "GammaMirageGripImage":
      return <GammaMirageGripImage { ...extras }/>
    case "GammaMirageOrangeImage":
      return <GammaMirageOrangeImage { ...extras }/>
    case "GammaMirageRedBlueImage":
      return <GammaMirageRedBlueImage { ...extras }/>
    case "GammaNeedleFrontImage":
      return <GammaNeedleFrontImage { ...extras }/>
    case "GammaNeedleGripImage":
      return <GammaNeedleGripImage { ...extras }/>
    case "GammaNeutronFrontImage":
      return <GammaNeutronFrontImage { ...extras }/>
    case "GammaNeutronGripImage":
      return <GammaNeutronGripImage { ...extras }/>
    case "GammaPinFrontImage":
      return <GammaPinFrontImage { ...extras }/>
    case "GammaPinGripImage":
      return <GammaPinGripImage { ...extras }/>
    case "GammaShardBlackImage":
      return <GammaShardBlackImage { ...extras }/>
    case "GammaShardBlueImage":
      return <GammaShardBlueImage { ...extras }/>
    case "GammaShardGripImage":
      return <GammaShardGripImage { ...extras }/>
    case "GammaVoltageFrontImage":
      return <GammaVoltageFrontImage { ...extras }/>
    case "GammaVoltageGripImage":
      return <GammaVoltageGripImage { ...extras }/>
    case "GearboxGx5ControlOrangeFrontImage":
      return <GearboxGx5ControlOrangeFrontImage { ...extras }/>
    case "GearboxGx5ControlRedFrontImage":
      return <GearboxGx5ControlRedFrontImage { ...extras }/>
    case "GearboxGx5PowerBlueFrontImage":
      return <GearboxGx5PowerBlueFrontImage { ...extras }/>
    case "GearboxGx5PowerGreenFrontImage":
      return <GearboxGx5PowerGreenFrontImage { ...extras }/>
    case "GearboxGx6ControlGreenFrontImage":
      return <GearboxGx6ControlGreenFrontImage { ...extras }/>
    case "GearboxGx6ControlRedFrontImage":
      return <GearboxGx6ControlRedFrontImage { ...extras }/>
    case "GearboxGx6PowerBlueFrontImage":
      return <GearboxGx6PowerBlueFrontImage { ...extras }/>
    case "GearboxGx6PowerNeonFrontImage":
      return <GearboxGx6PowerNeonFrontImage { ...extras }/>
    case "HeadChangesInLatitudesFrontImage":
      return <HeadChangesInLatitudesFrontImage { ...extras }/>
    case "HeadExtremeEliteFrontImage":
      return <HeadExtremeEliteFrontImage { ...extras }/>
    case "HeadExtremeLiteFrontImage":
      return <HeadExtremeLiteFrontImage { ...extras }/>
    case "HeadExtremeProFrontImage":
      return <HeadExtremeProFrontImage { ...extras }/>
    case "HeadExtremeTourFrontImage":
      return <HeadExtremeTourFrontImage { ...extras }/>
    case "HeadFinsFrontImage":
      return <HeadFinsFrontImage { ...extras }/>
    case "HeadIts5OclockSomewhereImage":
      return <HeadIts5OclockSomewhereImage { ...extras }/>
    case "HeadRadicalEliteFrontImage":
      return <HeadRadicalEliteFrontImage { ...extras }/>
    case "HeadRadicalProFrontImage":
      return <HeadRadicalProFrontImage { ...extras }/>
    case "HeadRadicalTourFrontImage":
      return <HeadRadicalTourFrontImage { ...extras }/>
    case "HeadRadicalTourLFrontImage":
      return <HeadRadicalTourLFrontImage { ...extras }/>
    case "HeadRadicalXlBlueFrontImage":
      return <HeadRadicalXlBlueFrontImage { ...extras }/>
    case "HeadRadicalXlRedFrontImage":
      return <HeadRadicalXlRedFrontImage { ...extras }/>
    case "HeadWashedInTheOceanFrontImage":
      return <HeadWashedInTheOceanFrontImage { ...extras }/>
    case "HudefHammerBlackFrontImage":
      return <HudefHammerBlackFrontImage { ...extras }/>
    case "HudefHammerGoldFrontImage":
      return <HudefHammerGoldFrontImage { ...extras }/>
    case "HudefHammerOrangeFrontImage":
      return <HudefHammerOrangeFrontImage { ...extras }/>
    case "HudefHb20BlueFrontImage":
      return <HudefHb20BlueFrontImage { ...extras }/>
    case "HudefHb20OrangeFrontImage":
      return <HudefHb20OrangeFrontImage { ...extras }/>
    case "HudefHb20PurpleFrontImage":
      return <HudefHb20PurpleFrontImage { ...extras }/>
    case "HudefHb20RedFrontImage":
      return <HudefHb20RedFrontImage { ...extras }/>
    case "OnixCompositeEvokeTeardropBlackFrontImage":
      return <OnixCompositeEvokeTeardropBlackFrontImage { ...extras }/>
    case "OnixCompositeEvokeTeardropBlueFrontImage":
      return <OnixCompositeEvokeTeardropBlueFrontImage { ...extras }/>
    case "OnixCompositeEvokeTeardropGreenFrontImage":
      return <OnixCompositeEvokeTeardropGreenFrontImage { ...extras }/>
    case "OnixCompositeEvokeTeardropPurpleFrontImage":
      return <OnixCompositeEvokeTeardropPurpleFrontImage { ...extras }/>
    case "OnixCompositeEvokeTeardropRedFrontImage":
      return <OnixCompositeEvokeTeardropRedFrontImage { ...extras }/>
    case "OnixCompositeZ5BlackFrontImage":
      return <OnixCompositeZ5BlackFrontImage { ...extras }/>
    case "OnixCompositeZ5BlueFrontImage":
      return <OnixCompositeZ5BlueFrontImage { ...extras }/>
    case "OnixCompositeZ5GreenFrontImage":
      return <OnixCompositeZ5GreenFrontImage { ...extras }/>
    case "OnixCompositeZ5OrangeFrontImage":
      return <OnixCompositeZ5OrangeFrontImage { ...extras }/>
    case "OnixCompositeZ5PinkFrontImage":
      return <OnixCompositeZ5PinkFrontImage { ...extras }/>
    case "OnixCompositeZ5PurpleFrontImage":
      return <OnixCompositeZ5PurpleFrontImage { ...extras }/>
    case "OnixCompositeZ5RedFrontImage":
      return <OnixCompositeZ5RedFrontImage { ...extras }/>
    case "OnixCompositeZ5YellowFrontImage":
      return <OnixCompositeZ5YellowFrontImage { ...extras }/>
    case "OnixEvokePremierFrontImage":
      return <OnixEvokePremierFrontImage { ...extras }/>
    case "OnixEvokeProBlueFrontImage":
      return <OnixEvokeProBlueFrontImage { ...extras }/>
    case "OnixEvokeProGreenFrontImage":
      return <OnixEvokeProGreenFrontImage { ...extras }/>
    case "OnixEvokeProOrangeFrontImage":
      return <OnixEvokeProOrangeFrontImage { ...extras }/>
    case "OnixEvokeProPurpleFrontImage":
      return <OnixEvokeProPurpleFrontImage { ...extras }/>
    case "OnixGraphiteEvokeTeardropBlueFrontImage":
      return <OnixGraphiteEvokeTeardropBlueFrontImage { ...extras }/>
    case "OnixGraphiteEvokeTeardropGreenFrontImage":
      return <OnixGraphiteEvokeTeardropGreenFrontImage { ...extras }/>
    case "OnixGraphiteEvokeTeardropPurpleFrontImage":
      return <OnixGraphiteEvokeTeardropPurpleFrontImage { ...extras }/>
    case "OnixGraphiteEvokeTeardropRedFrontImage":
      return <OnixGraphiteEvokeTeardropRedFrontImage { ...extras }/>
    case "OnixGraphiteEvokeTeardropWhiteFrontImage":
      return <OnixGraphiteEvokeTeardropWhiteFrontImage { ...extras }/>
    case "OnixGraphiteZ5BlueFrontImage":
      return <OnixGraphiteZ5BlueFrontImage { ...extras }/>
    case "OnixGraphiteZ5BlueNewFrontImage":
      return <OnixGraphiteZ5BlueNewFrontImage { ...extras }/>
    case "OnixGraphiteZ5GreenFrontImage":
      return <OnixGraphiteZ5GreenFrontImage { ...extras }/>
    case "OnixGraphiteZ5OrangeFrontImage":
      return <OnixGraphiteZ5OrangeFrontImage { ...extras }/>
    case "OnixGraphiteZ5PinkFrontImage":
      return <OnixGraphiteZ5PinkFrontImage { ...extras }/>
    case "OnixGraphiteZ5PinkNewFrontImage":
      return <OnixGraphiteZ5PinkNewFrontImage { ...extras }/>
    case "OnixGraphiteZ5PurpleFrontImage":
      return <OnixGraphiteZ5PurpleFrontImage { ...extras }/>
    case "OnixGraphiteZ5RedFrontImage":
      return <OnixGraphiteZ5RedFrontImage { ...extras }/>
    case "OnixGraphiteZ5WhiteFrontImage":
      return <OnixGraphiteZ5WhiteFrontImage { ...extras }/>
    case "OnixGraphiteZ5YellowFrontImage":
      return <OnixGraphiteZ5YellowFrontImage { ...extras }/>
    case "OnixOutbreakFrontImage":
      return <OnixOutbreakFrontImage { ...extras }/>
    case "OnixReactBlackFrontImage":
      return <OnixReactBlackFrontImage { ...extras }/>
    case "OnixReactGreenFrontImage":
      return <OnixReactGreenFrontImage { ...extras }/>
    case "OnixStryker4CompositeBlueFrontImage":
      return <OnixStryker4CompositeBlueFrontImage { ...extras }/>
    case "OnixStryker4CompositeGreenFrontImage":
      return <OnixStryker4CompositeGreenFrontImage { ...extras }/>
    case "OnixStryker4CompositeRedFrontImage":
      return <OnixStryker4CompositeRedFrontImage { ...extras }/>
    case "OnixStryker4GraphitePurpleFrontImage":
      return <OnixStryker4GraphitePurpleFrontImage { ...extras }/>
    case "OnixStryker4GraphiteWhiteFrontImage":
      return <OnixStryker4GraphiteWhiteFrontImage { ...extras }/>
    case "OnixSummitC1OrangeFrontImage":
      return <OnixSummitC1OrangeFrontImage { ...extras }/>
    case "OnixVertexRedFrontImage":
      return <OnixVertexRedFrontImage { ...extras }/>
    case "OnixVoyagerProWhiteFrontImage":
      return <OnixVoyagerProWhiteFrontImage { ...extras }/>
    case "PaddletekBantamExLBlueFrontImage":
      return <PaddletekBantamExLBlueFrontImage { ...extras }/>
    case "PaddletekBantamExLPinkFrontImage":
      return <PaddletekBantamExLPinkFrontImage { ...extras }/>
    case "PaddletekBantamExLPurpleFrontImage":
      return <PaddletekBantamExLPurpleFrontImage { ...extras }/>
    case "PaddletekBantamExLRedFrontImage":
      return <PaddletekBantamExLRedFrontImage { ...extras }/>
    case "PaddletekBantamExLProAquaFrontImage":
      return <PaddletekBantamExLProAquaFrontImage { ...extras }/>
    case "PaddletekBantamExLProBlueFrontImage":
      return <PaddletekBantamExLProBlueFrontImage { ...extras }/>
    case "PaddletekBantamExLProPinkFrontImage":
      return <PaddletekBantamExLProPinkFrontImage { ...extras }/>
    case "PaddletekBantamExLProRedFrontImage":
      return <PaddletekBantamExLProRedFrontImage { ...extras }/>
    case "PaddletekBantamTs5BlueFrontImage":
      return <PaddletekBantamTs5BlueFrontImage { ...extras }/>
    case "PaddletekBantamTs5GrayFrontImage":
      return <PaddletekBantamTs5GrayFrontImage { ...extras }/>
    case "PaddletekBantamTs5PinkFrontImage":
      return <PaddletekBantamTs5PinkFrontImage { ...extras }/>
    case "PaddletekBantamTs5RedFrontImage":
      return <PaddletekBantamTs5RedFrontImage { ...extras }/>
    case "PaddletekBantamTs5YellowFrontImage":
      return <PaddletekBantamTs5YellowFrontImage { ...extras }/>
    case "PaddletekBantamTs5ProAquaFrontImage":
      return <PaddletekBantamTs5ProAquaFrontImage { ...extras }/>
    case "PaddletekBantamTs5ProBlueFrontImage":
      return <PaddletekBantamTs5ProBlueFrontImage { ...extras }/>
    case "PaddletekBantamTs5ProPurpleFrontImage":
      return <PaddletekBantamTs5ProPurpleFrontImage { ...extras }/>
    case "PaddletekBantamTs5ProRedFrontImage":
      return <PaddletekBantamTs5ProRedFrontImage { ...extras }/>
    case "PaddletekElementBlueFrontImage":
      return <PaddletekElementBlueFrontImage { ...extras }/>
    case "PaddletekElementPinkFrontImage":
      return <PaddletekElementPinkFrontImage { ...extras }/>
    case "PaddletekElementRedFrontImage":
      return <PaddletekElementRedFrontImage { ...extras }/>
    case "PaddletekElementYellowFrontImage":
      return <PaddletekElementYellowFrontImage { ...extras }/>
    case "PaddletekPhoenixProBlackFrontImage":
      return <PaddletekPhoenixProBlackFrontImage { ...extras }/>
    case "PaddletekPhoenixProBlueFrontImage":
      return <PaddletekPhoenixProBlueFrontImage { ...extras }/>
    case "PaddletekPhoenixProGreenFrontImage":
      return <PaddletekPhoenixProGreenFrontImage { ...extras }/>
    case "PaddletekPhoenixProOrangeFrontImage":
      return <PaddletekPhoenixProOrangeFrontImage { ...extras }/>
    case "PaddletekPhoenixProPinkFrontImage":
      return <PaddletekPhoenixProPinkFrontImage { ...extras }/>
    case "PaddletekPhoenixProPurpleFrontImage":
      return <PaddletekPhoenixProPurpleFrontImage { ...extras }/>
    case "PaddletekPhoenixProRedFrontImage":
      return <PaddletekPhoenixProRedFrontImage { ...extras }/>
    case "PaddletekPhoenixProTurquoiseFrontImage":
      return <PaddletekPhoenixProTurquoiseFrontImage { ...extras }/>
    case "PaddletekPhoenixProYellowFrontImage":
      return <PaddletekPhoenixProYellowFrontImage { ...extras }/>
    case "PaddletekSabreProBlueFrontImage":
      return <PaddletekSabreProBlueFrontImage { ...extras }/>
    case "PaddletekSabreProRedFrontImage":
      return <PaddletekSabreProRedFrontImage { ...extras }/>
    case "PaddletekTangentProBlueFrontImage":
      return <PaddletekTangentProBlueFrontImage { ...extras }/>
    case "PaddletekTangentProRedFrontImage":
      return <PaddletekTangentProRedFrontImage { ...extras }/>
    case "PaddletekTempestProBlueFrontImage":
      return <PaddletekTempestProBlueFrontImage { ...extras }/>
    case "PaddletekTempestProRedFrontImage":
      return <PaddletekTempestProRedFrontImage { ...extras }/>
    case "PaddletekTempestWaveBlueFrontImage":
      return <PaddletekTempestWaveBlueFrontImage { ...extras }/>
    case "PaddletekTempestWavePinkFrontImage":
      return <PaddletekTempestWavePinkFrontImage { ...extras }/>
    case "PaddletekTempestWaveRedFrontImage":
      return <PaddletekTempestWaveRedFrontImage { ...extras }/>
    case "ProliteChromeNRGBlueFrontImage":
      return <ProliteChromeNRGBlueFrontImage { ...extras }/>
    case "ProliteChromeNRGRedFrontImage":
      return <ProliteChromeNRGRedFrontImage { ...extras }/>
    case "ProliteChromeNRGWhiteFrontImage":
      return <ProliteChromeNRGWhiteFrontImage { ...extras }/>
    case "ProliteCrushPowerspinSkateFrontImage":
      return <ProliteCrushPowerspinSkateFrontImage { ...extras }/>
    case "ProliteCrushPowerspinSnowFrontImage":
      return <ProliteCrushPowerspinSnowFrontImage { ...extras }/>
    case "ProliteCrushPowerspinSurfFrontImage":
      return <ProliteCrushPowerspinSurfFrontImage { ...extras }/>
    case "ProliteCypherProFrontImage":
      return <ProliteCypherProFrontImage { ...extras }/>
    case "ProliteLargeTitanFrontImage":
      return <ProliteLargeTitanFrontImage { ...extras }/>
    case "ProliteMagnumGsBlueFrontImage":
      return <ProliteMagnumGsBlueFrontImage { ...extras }/>
    case "ProliteMagnumGsRedFrontImage":
      return <ProliteMagnumGsRedFrontImage { ...extras }/>
    case "ProliteRebelPowerspinBlueFrontImage":
      return <ProliteRebelPowerspinBlueFrontImage { ...extras }/>
    case "ProliteRebelPowerspinGoldFrontImage":
      return <ProliteRebelPowerspinGoldFrontImage { ...extras }/>
    case "ProliteRebelPowerspinPurpleFrontImage":
      return <ProliteRebelPowerspinPurpleFrontImage { ...extras }/>
    case "ProliteRebelPowerspinRedFrontImage":
      return <ProliteRebelPowerspinRedFrontImage { ...extras }/>
    case "ProliteSupernovaProFrontImage":
      return <ProliteSupernovaProFrontImage { ...extras }/>
    case "ProliteSurfaceNrgBlueFrontImage":
      return <ProliteSurfaceNrgBlueFrontImage { ...extras }/>
    case "ProliteSurfaceNrgGreenFrontImage":
      return <ProliteSurfaceNrgGreenFrontImage { ...extras }/>
    case "ProliteSurfaceNrgOrangeFrontImage":
      return <ProliteSurfaceNrgOrangeFrontImage { ...extras }/>
    case "ProliteSurfaceNrgPurpleFrontImage":
      return <ProliteSurfaceNrgPurpleFrontImage { ...extras }/>
    case "ProliteTitanProFrontImage":
      return <ProliteTitanProFrontImage { ...extras }/>
    case "SelkirkAmpedEpicFrontImage":
      return <SelkirkAmpedEpicFrontImage { ...extras }/>
    case "SelkirkAmpedInviktaFrontImage":
      return <SelkirkAmpedInviktaFrontImage { ...extras }/>
    case "SelkirkAmpedMaximaFrontImage":
      return <SelkirkAmpedMaximaFrontImage { ...extras }/>
    case "SelkirkAmpedOmniFrontImage":
      return <SelkirkAmpedOmniFrontImage { ...extras }/>
    case "SelkirkAmpedS2FrontImage":
      return <SelkirkAmpedS2FrontImage { ...extras }/>
    case "SelkirkPrimeEpicFrontImage":
      return <SelkirkPrimeEpicFrontImage { ...extras }/>
    case "SelkirkPrimeS2FrontImage":
      return <SelkirkPrimeS2FrontImage { ...extras }/>
    default:
      return null
  }
}
