import * as React from "react"

interface Props {
  title: string;
  subtitle: string;
}

export const Hero = ({ title, subtitle }: Props) => (
  <section className="hero is-primary">
    <div className="hero-body">
      <div className="container">
        <h1 className="title">
          { title }
        </h1>
        <h2 className="subtitle">
          { subtitle }
        </h2>
      </div>
    </div>
  </section>
)
