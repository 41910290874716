import * as React from "react"
import { Link } from "@reach/router"
import "./styles.scss"

interface Props {
  siteTitle: string;
}

export const Navbar = ({ siteTitle }: Props) => {
  const [isNavExpanded, setNavExpanded] = React.useState(false)

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img src="/pickleball-armory-155-web-crop-300.png" height="55"/>
        </a>

        <a role="button" className="navbar-burger burger" aria-label="menu" aria-expanded="false"
           onClick={ () => setNavExpanded(!isNavExpanded) }>
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>

      <div className={ `navbar-menu ${ isNavExpanded ? "is-active" : "" }` }>
        <div className="navbar-start">
          <Link to={ `paddle-finder` } className="navbar-item">
            Paddle Finder
          </Link>
          {/*<div className="navbar-item has-dropdown is-hoverable">*/}
          {/*  <a className="navbar-link">*/}
          {/*    More*/}
          {/*  </a>*/}

          {/*  <div className="navbar-dropdown">*/}
          {/*    <a className="navbar-item">*/}
          {/*      About*/}
          {/*    </a>*/}
          {/*    <a className="navbar-item">*/}
          {/*      Jobs*/}
          {/*    </a>*/}
          {/*    <a className="navbar-item">*/}
          {/*      Contact*/}
          {/*    </a>*/}
          {/*    <hr className="navbar-divider" />*/}
          {/*    <a className="navbar-item">*/}
          {/*      Report an issue*/}
          {/*    </a>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </nav>
  )
}
