import * as React from "react"
import { Paddle } from "../../models/Paddle"
import "./index.scss"

interface Props {
  paddle: Paddle;
}

export const PaddleSpecifications = ({ paddle }: Props) => {
  return (
    <div className="panel" id="PaddleSpecifications">
      <div className="panel-heading">
        Specifications
      </div>
      <div className="panel-block is-flex">
        <span className="has-text-weight-semibold">Paddle dimensions:&nbsp;</span>
        { `${ paddle.paddle_width }" x ${ paddle.paddle_length }"` }
      </div>
      <div className="panel-block is-flex">
        <span className="has-text-weight-semibold">Grip Length:&nbsp;</span>
        { ` ${ paddle.grip_length }"` }
      </div>
      <div className="panel-block is-flex">
        <span className="has-text-weight-semibold">Grip Circumference:&nbsp;</span>
        { ` ${ paddle.grip_circumference }"` }
      </div>
      <div className="panel-block is-flex">
        <span className="has-text-weight-semibold">Weight Range:&nbsp;</span>
        { ` ${ paddle.weight_min } - ${ paddle.weight_max }oz` }
      </div>
      <div className="panel-block is-flex">
        <span className="has-text-weight-semibold">Surface Material:&nbsp;</span>
        { ` ${ paddle.surface_material }` }
      </div>
      <div className="panel-block is-flex">
        <span className="has-text-weight-semibold">Core Material:&nbsp;</span>
        { ` ${ paddle.core_material }` }
      </div>
      <div className="panel-block is-flex">
        <span className="has-text-weight-semibold">MSRP:&nbsp;</span>
        { ` $${ paddle.msrp }` }
      </div>
      { paddle.made_in_usa
        ? <div className="panel-block has-text-weight-semibold">
          Made in USA
        </div>
        : null }
    </div>
  )
}
